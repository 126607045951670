<mat-card appearance="outlined" *ngIf="timeRange$ | async as timeRange">
  <div class="ctr-calendarization-title-header">
    <div class="title-section">
      <mat-card-title class="cost-over-time-label"
        >Cost over time: {{ getTitleUnits$ | async }}</mat-card-title
      >
      <button
        mat-button
        color="primary"
        class="info-button"
        [matTooltip]="
          'By default hours matrix for CTR is obtained by evenly distributing total number of hours over each time period, between CTR start and end dates. Within the default distribution, due to rounding errors, the total number of hours may not be exactly equal to the sum of hours in each period.'
        "
      >
        <mat-icon>info_outline</mat-icon>
      </button>
    </div>
    <div class="action-buttons-section">
      <mat-button-toggle-group
        class="mat-button-toggle-group"
        (change)="selectedViewChanged($event.value)"
        [value]="selectedView$ | async"
      >
        <mat-button-toggle
          [value]="chartViewType.MATRIX"
          matTooltip="Switch to matrix view"
          data-test="detailed-view-switch-to-matrix-view-button"
        >
          <mat-icon>schedule</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          [value]="chartViewType.FTE"
          class="fte-toggle-button"
          [matTooltip]="'Switch to FTE view'"
          data-test="detailed-view-switch-to-fte-view-button"
        >
          FTE
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div class="vertical-line-separator"></div>

      <button
        class="actions-button"
        mat-stroked-button
        color="primary"
        (click)="distributeEvenly()"
        data-test="calendarization-distribute-evenly-button"
        [matTooltip]="
          editModeEnabled
            ? 'Cannot distribute evenly in edit mode'
            : (userIsOwner$ | async)
            ? 'Distribute evenly'
            : 'Only CTR Owner can change the calendarization.'
        "
        [disabled]="editModeEnabled || (userIsOwner$ | async) === false"
      >
        <mat-icon>view_week</mat-icon>
      </button>

      <div *ngIf="userIsOwner$ | async">
        <div
          class="vertical-line-separator"
          *ngIf="isEditCalendarizationFeatureEnabled$ | async"
        ></div>
        <button
          class="actions-button"
          mat-stroked-button
          color="primary"
          *ngIf="isEditCalendarizationFeatureEnabled$ | async"
          data-test="edit-roles-fte-button"
          [disabled]="(selectedView$ | async) !== chartViewType.FTE"
          matTooltip="Edit FTEs"
          (click)="changeEditMode()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div>
        <div class="vertical-line-separator" style="display: none"></div>

        <button
          class="actions-button image-color"
          mat-stroked-button
          color="primary"
          [matTooltip]="'Export to Excel'"
          style="display: none"
        >
          <mat-icon svgIcon="xls-icon"></mat-icon>
        </button>

        <button
          class="actions-button image-color"
          mat-stroked-button
          color="primary"
          [matTooltip]="'Export to Client Excel'"
          style="display: none"
        >
          <mat-icon svgIcon="xls-client-icon"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="filters-section">
    <mat-form-field>
      <mat-label>Filter by entity</mat-label>
      <input
        matInput
        #entityInput
        (input)="applyFilter($event.target.value, 'entity')"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Filter by type</mat-label>
      <input
        matInput
        #roleTypeInput
        (input)="applyFilter($event.target.value, 'roleType')"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Filter by role</mat-label>
      <input
        matInput
        #roleNameInput
        (input)="applyFilter($event.target.value, 'roleName')"
      />
    </mat-form-field>
    <button
      mat-button
      class="clear-filters-btn"
      (click)="clearFilters(entityInput, roleTypeInput, roleNameInput)"
    >
      Clear filters
    </button>
  </div>

  <mat-card-content>
    <div class="table-scroll-wrapper">
      <table class="ctr-calendarization" aria-label="ctr-calendarization">
        <thead class="sticky-top entity-type-role-columns">
          <tr>
            <th
              rowspan="2"
              scope="col"
              class="name nowrap entity-col entity-col-header"
            >
              Entity
              <app-roles-sort-button
                [sortConfiguration$]="sortConfiguration$"
                columnName="entity"
                (sortChange)="onSortChange($event)"
              ></app-roles-sort-button>
            </th>
            <th rowspan="2" class="name nowrap type-col" scope="col">
              Type
              <app-roles-sort-button
                [sortConfiguration$]="sortConfiguration$"
                columnName="roleType"
                (sortChange)="onSortChange($event)"
              ></app-roles-sort-button>
            </th>
            <th rowspan="2" class="name nowrap roles-col" scope="col">
              Roles
              <app-roles-sort-button
                [sortConfiguration$]="sortConfiguration$"
                columnName="roleName"
                (sortChange)="onSortChange($event)"
              ></app-roles-sort-button>
            </th>
            <th
              *ngFor="let year of timeRange.years"
              [attr.colspan]="year.months.length"
              class="time-unit"
              scope="col"
            >
              <span class="sticky-year">{{ year.yearIndex }}</span>
            </th>
            <th rowspan="2" class="sticky-right" scope="col">Total</th>
          </tr>
          <tr>
            <ng-container *ngFor="let year of timeRange.years">
              <th *ngFor="let month of year.months" class="time-unit">
                {{ month.monthShortName }}
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container [ngSwitch]="selectedView$ | async">
            <ng-container *ngSwitchCase="chartViewType.MATRIX">
              <tr
                class="entity-type-role-columns"
                *ngFor="let role of filteredAndSortedHoursForRoles$ | async"
              >
                <td class="name nowrap entity-col">
                  {{ role.entityName }}
                </td>
                <td class="name nowrap type-col">
                  {{ role.roleType }}
                </td>
                <td
                  class="name nowrap roles-col"
                  [attr.data-test]="
                    'details-role-name-label-role-id-' +
                    role.engineeringRoleId +
                    '-entity-id-' +
                    role.entityId
                  "
                >
                  {{ role.roleName }}
                </td>
                <ng-container *ngFor="let year of role.hoursDistribution">
                  <td
                    class="data-cells"
                    *ngFor="let period of year.hoursPeriods"
                  >
                    {{
                      period.hoursInPeriod | integer : period.isPeriodEditable
                    }}
                  </td>
                </ng-container>
                <td
                  class="total sticky-right"
                  [attr.data-test]="
                    'details-total-hours-by-role-value-' +
                    role.engineeringRoleId +
                    '-entity-id-' +
                    role.entityId
                  "
                >
                  {{ role.totalHours | integer : true }}
                </td>
              </tr>
            </ng-container>

            <ng-container *ngSwitchCase="chartViewType.FTE">
              <ng-container
                *ngIf="filteredAndSortedFteForRoles$ | async as form"
              >
                <tr
                  class="entity-type-role-columns"
                  *ngFor="let role of form.controls"
                >
                  <td class="name nowrap entity-col">
                    {{ role.controls.entityName.value }}
                  </td>
                  <td class="name nowrap type-col">
                    {{ role.controls.roleType.value }}
                  </td>
                  <td
                    class="name nowrap roles-col role-name-with-fte-leftovers"
                    [attr.data-test]="
                      'details-role-name-label-' +
                      role.controls.engineeringRoleId.value +
                      '-entity-id-' +
                      role.controls.entityId.value
                    "
                  >
                    {{ role.controls.roleName.value }}

                    <span
                      class="overestimated-hours"
                      data-test="fte-distribution-value"
                      [class]="role.valid ? 'valid-info' : 'invalid-info'"
                    >
                      {{
                        role.errors?.overEstimated ??
                          (editModeEnabled ? 'OK' : '')
                      }}
                    </span>
                  </td>
                  <ng-container
                    *ngFor="let year of role.controls.fteDistribution.controls"
                  >
                    <td
                      class="data-cells"
                      [class]="editModeEnabled ? 'cell-edit-mode' : ''"
                      *ngFor="let period of year.controls.ftePeriods.controls"
                    >
                      <ng-container
                        *ngIf="!editModeEnabled; else inputTemplate"
                      >
                        {{
                          period.controls.fteInPeriod.value
                            | oneDecimal
                              : period.controls.isPeriodEditable.value
                        }}
                      </ng-container>
                      <ng-template #inputTemplate>
                        <input
                          *ngIf="period.controls.isPeriodEditable.value"
                          type="number"
                          [formControl]="period.controls.fteInPeriod"
                          data-test="fte-distribution-period-input"
                          class="input-field input-edit-hours no-arrows"
                          [class]="
                            period.controls.fteInPeriod.valid
                              ? 'valid'
                              : 'invalid'
                          "
                          matTooltip="Only one digit  after the decimal point is allowed!
                          Value must be greater than or equal to 0."
                          [matTooltipDisabled]="
                            !period.controls.fteInPeriod.errors
                          "
                        />
                      </ng-template>
                    </td>
                  </ng-container>
                  <td
                    class="total sticky-right"
                    [attr.data-test]="
                      'details-total-hours-by-role-value-' +
                      role.controls.engineeringRoleId.value +
                      '-entity-id-' +
                      role.controls.entityId.value
                    "
                  >
                    {{
                      role.controls.expectedTotalFte.value | oneDecimal : true
                    }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>

        <tfoot class="sticky-bottom">
          <tr>
            <th class="sticky-left total" scope="col" colspan="3">Total</th>
            <ng-container *ngFor="let year of timeRange.years">
              <th *ngFor="let month of year.months" class="total">
                <app-calendarization-table-total-in-month
                  [selectedView]="selectedView$ | async"
                  [month]="month"
                ></app-calendarization-table-total-in-month>
              </th>
            </ng-container>
            <th
              class="sticky-right total total-amount"
              *ngIf="totalAmounts$ | async as totalAmounts"
              data-test="details-total-amount-value"
            >
              <ng-container [ngSwitch]="selectedView$ | async">
                <ng-container *ngSwitchCase="chartViewType.FTE">
                  {{ totalAmounts.fte | oneDecimal : true }}
                </ng-container>
                <ng-container *ngSwitchCase="chartViewType.MATRIX">
                  {{ totalAmounts.hours | integer : true }}
                </ng-container>
              </ng-container>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </mat-card-content>
</mat-card>
